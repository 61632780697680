$hover-opacity: 0.7;

/* icomoon segment */
@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?4o1lak');
    src:  url('fonts/icomoon.eot?4o1lak#iefix') format('embedded-opentype'),
      url('fonts/icomoon.ttf?4o1lak') format('truetype'),
      url('fonts/icomoon.woff?4o1lak') format('woff'),
      url('fonts/icomoon.svg?4o1lak#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
  
[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 30px;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
.icon-github:before {
    content: "\eab0";
}
.icon-linkedin:before {
    content: "\eac9";
}
.icon-codepen:before {
    content: "\eae8";
}
/* end icomoon segment */

body{
    background-color: #ffda95;
    padding: 0;
    margin: 0;
    text-align: center;
    font: 18px Tahoma;
}

header, section{
    height: 100vh;
}

header{
    background-color: #feffff;
    position: relative;
    min-height: 540px;
}

.container-intro{
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
    width: 100%;
}

p{
    text-align: left;
}

h1{
    margin-top: 0;
}

h2{
    text-align: left;
    padding: 10px 5px;
    margin: 0;
}

a{
    color: #493939;
    text-decoration: none;
}

a:hover{
    opacity: $hover-opacity;
    cursor: pointer;
}

.icon{
    margin: 0 10px;
}

.description{
    text-align: center;
}

.container-social{
    margin-top: 20px;
}

.container-content{
    background-color: #ffda95;
    height: 500px;
    margin: 0 15px;
}

.container-projects-link{
    position: absolute;
    transform: translateY(-50%);
    width: 100%;
    bottom: 15px;
}

.container-language{
    float: right;
    padding: 15px;
    font-size: 17px;
}

.down-arrow{
    transform: rotate(45deg);
    border-bottom: 5px solid black;
    border-right: 5px solid black;
    width: 15px;
    height: 15px;
    margin-left: auto;
    margin-right: auto;
}

